.navbar {
    position: sticky;
    width: 100%;
    margin: auto;
    text-align: center;
    z-index: 50;
    background-color: white;
}

.navbar-link {
    font-size: 1.2em;
    font-family: Futura, sans-serif;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    margin: auto;
    padding: .5em 1em;
    color: rgb(72,31,75);
}

.navbar-link:hover {
    transition: color 500ms;
    color: rgb(198, 171, 210);
    -webkit-transition: color 500ms;
    -moz-transition: color 500ms;
    -ms-transition: color 500ms;
    -o-transition: color 500ms;
}

.navbar-child {
    width: 12em;
}

.navbar-submenu-arrow {
    fill: var(--main-accent-color);
}

.navbar-main-nav {
    height: 10em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    padding-left: 0px;
    width: 100%;
}

.navbar-navbutton {
    width: 12em;
    height: 3em;
    position: relative;
}

.navbar-logo-container {
    width: 10em;
}
.navbar-logo {
    height: 100%;
}

.navbar-submenu {
    background-color: white;
    display: flex;
    position: relative;
    align-items: center;
    padding: unset;
    margin: unset;
    flex-direction: column;
    width: 100%;
    transition: height 1000ms ease-out;
}

#navbar-submenu-mark {
    width: 1em;
    height: auto;
}

.navbar-social-flex {
    position: absolute;
    top: 0%;
    right: 5%;
    border-bottom: .1em solid rgba(95, 79, 80, .1);
}

.navbar-social {
    height: 100%;
    width: auto;
    padding: 0em .5em;
}

.navbar-social:hover {
    cursor: pointer;
}

.navbar-socialimage {
    width: 1.5em;
    fill: #fde5b7;
    transition: fill 500ms;
    -webkit-transition: fill 500ms;
    -moz-transition: fill 500ms;
    -ms-transition: fill 500ms;
    -o-transition: fill 500ms;
}

.navbar-socialimage:hover {
    fill: var(--secondary-orange);
}

.navbar-submenu-children {
    display: inline;
}

.navbar-menuRetracted {
    position: fixed;
    top: 1em;
    right: 1em;
}

@media only screen and (max-width: 1070px) {
    .navbar-link {
        font-size: 1em;
        padding: .5em 1.5em;
    }
}

@media only screen and (max-width: 770px) {
    .navbar-socialimage {
        width: 1.2em;
    }


}
@media only screen and (max-width: 770px) and (min-width: 600px) {
    .navbar-link {
        font-size: .8em;
    }
}

@media only screen and (max-width: 600px) {
    .navbar-submenu {
        align-items: start;
    }

    .navbar-navbutton {
        width: 15em;
        height: auto;
    }

    .navbar-link, #navbar-submenu-mark {
        display: inline-block;
    }

    .navbar-link {
        width: 10em;
        text-align: left;
    }

    .navbar-submenu-drop {
        background: linear-gradient(90deg, rgba(198,171,210,1) 0%, rgba(255,255,255,1) 50%);
        padding-left: 1em;
    }
}