.footer {
    background: rgb(72,31,75);
    height: auto;
    width: 100%;
}

.footer-item {
    text-align: center;
    padding: 2em;
}

.footer-itemtext {
    color: white;
    font-family: Futura;
}

.footer-flexrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#footer-logo {
    height: 10em;
    padding: 2em;
}

#footer-image {
    height: 100%;
}

#footer-links {
    min-height: 7em;
    border-top: .1em solid white;
    border-bottom: .1em solid white;
    padding: 1em;
}

#footer-socials-contianer {
    padding: 2em;
}

#footer-socials {
    height: 2em;
    width: auto;
}

#footer-rights {
    height: 3em;
    padding-bottom: 1em;
}

.footer-disclaimer {
    padding: 1em;
    font-size: .8em;
    color: white;
    text-align: center;
    width: 80%;
}

.footer-social {
    padding: .5em;
    cursor: pointer;
    transition: transform 1s ease;
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
    -moz-transform: scale(1.0);
    -ms-transform: scale(1.0);
    -o-transform: scale(1.0);
    -webkit-transition: transform 1s ease;
    -moz-transition: transform 1s ease;
    -ms-transition: transform 1s ease;
    -o-transition: transform 1s ease;
}

.footer-social:hover {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}


@media only screen and (max-width: 900px){
    .footer-item {
        padding: 1.3em;
        font-size: .7em;
    }
}

@media only screen and (max-width: 700px){
    .footer-item {
        padding: .7em;
        font-size: .7em;
    }
    #footer-links {
        flex-direction: column;
        padding: .7em;
    }
}