.titleCard-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: rgb(198, 171, 210);
    background-position-y: 60%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
    min-height: 300px;
    z-index: 1;
    width: auto;
}

.titleCard-flexitem {
    color: rgba(255,255,255,0.8);   
    margin: 0px;
    padding: 1em .5em;
}

#titlecard-title {
    font-size: 3em;
}

#titlecard-subtext {
    font-size: 1.5em;
    font-family: Futura;
    color: #f1d9ab;
}

@media only screen and (max-width: 1200px) {
    .titleCard-container {
        background-position-y: 60%;
    }

    #titlecard-title {
        font-size: 2.3em;
    }
}

@media only screen and (max-width: 910px) {
    .titleCard-container {
        background-position: 40% 20%;
        background-size: cover;
        height: 60vh;
    }


}

@media only screen and (max-width: 710px) {
    .titleCard-container {
        height: 40vh
    }

    #titlecard-title {
        font-size: 1.5em;
    }

    #titlecard-subtext {
        font-size: 1em;
    }
}

@media only screen and (max-width: 475px) {
    .titleCard-container {
        background-size: 180%;
        background-position: 50% 30%;
    }

    #titlecard-title {
        font-size: 1.5em;
    }

    #titlecard-subtext {
        font-size: .8em;
    }
}