#joinus {
    background-image: url('../../../Pics/Landscapes/jpeg/WORK_WITH_US.jpg');
    background-position-y: 30%;
    background-size: cover;
    background-color: var(--main-accent-color);
    background-repeat: no-repeat;
    height: auto;
    min-height: 400px;
}

.joinus-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    padding: 2em 0em;
}

.joinus-flexItem {
    width: 50%;
}

#joinus-flexFirst {
    width: 40%;
}

#joinus-textSection {
    padding-right: 3em;
    width: 60%;
}

.joinus-leftText, #joinus-text {
    text-shadow: 0em 0em .5em rgba(0,0,0,.5);
}

.joinus-leftText {
    color: var(--white-seethrough);
    text-align: left;
    width: 80%;
    margin: auto;
    line-height: 1.5em;
}

#joinus-text {
    font-size: 1.3em;
    color: white;
    margin: .2em 0em;
}

#joinus-joinButton {
    margin: 1em 0em;
}

@media only screen and (max-width: 1200px) {
    #joinus {
        height: auto;
    }

    #joinus-textSection {
        width: 55%;
        margin: auto;
    }

    #joinus-text {
        font-size: 1.2em;
        padding: .1em 0em;
    }

    .joinus-leftText {
        font-size: 5em;
    }
}

@media only screen and (max-width: 940px) {
    #joinus {
        background-position-x: 30%;
        }
    
    #joinus-text {
        font-size: 1em;
    }

    .joinus-leftText {
        font-size: 3.5em;
    }
}

@media only screen and (max-width: 750px) {
    .joinus-flex {
        flex-direction: column;
    }

    .joinus-leftText {
        font-size: 3.7em;
        display: inline-block;
        width: auto;
        padding: .5em .3em .5em 0em;
    }

    #joinus-flexFirst {
        width: 88%;
    }

    #joinus-text {
        font-size: 1.1em;
    }

    .joinus-flexItem {
        width: 80%;
    }

    #joinus-textSection {
        width: 80%;
    }

    #joinus-heading {
        padding-top: 1em;
    }
}

@media only screen and (max-width: 560px) {
    #joinus {
        width: 100%;
        background-position-x: 40%;
    }

    
    .joinus-leftText, #joinus-textSection {
        text-align: center;
        padding: .5em;
    }
    
    .joinus-leftText {
        font-size: 3em;
        width: 100%;
        padding: .2em 0em;
    }

    #joinus-text {
        font-size: 1em;
    }
}