.offering-sectionmain {
    padding-bottom: 5em;
}

.offering-section-flex {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: space-around;
}

.offering-section-title {
    font-size: 2em;
    padding: 2em 0em;
    color: white;
}

.offering-section-flexbox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: .1em solid #c7b3ce;
    width: 29%;
    padding-bottom: 2em;
}

.offering-section-title-container, .offering-section-text-container, .offering-section-flexbox {
    text-align: center;
}

#offering-yogasunrise, 
#offering-moonmountain, 
#offering-groupyoga {
    background-size: cover;
}

#offering-yogasunrise {
    background-image: url('../../../Pics/Landscapes/jpeg/yogaSunrise_dark.jpg');
}

#offering-moonmountain {
    background-image: url('../../../Pics/Landscapes/jpeg/mountainPool.jpg');
}

#offering-groupyoga {
    background-image: url('../../../Pics/Landscapes/jpeg/girlsInField_dark.jpg');
    background-position-x: 30%;
}

.offering-section-title-container {
    padding: 1em;
}

.offering-section-text-container {
    overflow: hidden;
    height: auto;
    min-height: 12em;
    margin: 2em 0em;
    cursor: ns-resize;
}

.offering-section-scrollhide {
    display: inline-block;
    position: relative;
    padding-right: .5em;
    width: 105%;
    height: 100%;
    overflow: auto;
}

.offering-section-text {
    font-size: 1.2em;
    margin: 0em 1em;
    padding-right: 1em;
}

.offering-section-image-container {
    width: 100%;
    height: auto;
}

.offering-section-image {
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 1500px) {
    .offering-section-text {
        min-height: 12em;
    }
}

@media only screen and (max-width: 1100px) {
    .offering-section-text {
        padding: 0px;
        min-height: 0em;
    }

    .offering-section-flexbox {
        width: 90%;
    }

    .offering-section-title {
        font-size: 3em;
        padding: 1em;
        color: white;
    }

    #offering-yogasunrise, #offering-moonmountain, #offering-groupyoga {
        background-position-y: 45%;
      }
}

@media only screen and (max-width: 650px) {
    .offering-section-title {
        font-size: 2.4em
    }
    
    .offering-section-title-container {
        padding: 1em;
    }
}
