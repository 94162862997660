.comingsoon {
    background-position: 50% 50%;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.comingsoon-heading, .comingsoon-subtext {
    text-shadow: 0em 0em .2em rgba(0,0,0,.5);
    color: white;
}

.comingsoon-heading {
    font-size: 2em;
    margin-bottom: 0em;
}

.comingsoon-subtext {
    text-align: center;
    font-size: 1em;
    width: 30em;
    max-width: 80%;
}

.comingsoon-sparkle {
    width: 10em;
    height: auto;
}
