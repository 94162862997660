/*
  main - violet                         rgb(72,31,75)
  main complement - light purple        rgb(198, 171, 210)
  accent 1 - grey                       rgb(202, 201, 171)
  accent 2 - light blue                 rgb(183, 200, 219)
  accent 3 - light pink                 rgb(253, 229, 183)
  accent 4 - light orange               rgb(241, 199, 97)
*/
:root {
  --main-color: rgb(72, 31, 75);
  --main-accent-color: rgb(198, 171, 210);
  --text-color-grey: rgb(55, 55, 55);
  --secondary-orange: rgb(241, 199, 97);
  --secondary-green: #bdbc9e;
  --white-seethrough: rgba(255, 255, 255, 0.8);
}

@font-face {
  font-family: bivoac-regular;
  src: url('./Fonts/Bivoac Reguler.woff');
  src: url('./Fonts/Bivoac Reguler.woff') format('woff'),
       url('./Fonts/Bivoac Reguler.otf') format('opentype')
}

@font-face {
  font-family: Busorama-ITC-Std-Bold;
  src: url('./Fonts/Busorama-ITC-Std-Bold.woff');
  src: url('./Fonts/Busorama-ITC-Std-Bold.woff') format('woff');
}

@font-face {
  font-family: Futura;
  src: url('./Fonts/FuturaBook.ttf');
  src: url('./Fonts/FuturaBook.ttf') format('truetype');
}

@font-face {
  font-family: Futura-Thin;
  src: url('./Fonts/FuturaBook.ttf');
  src: url('./Fonts/FuturaBook.ttf') format('truetype');
}

@font-face {
  font-family: Futura-bold;
  src: url('./Fonts/FuturaStdBook.ttf');
  src: url('./Fonts/FuturaStdBook.ttf') format('truetype');
}

html {
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: rgb(72, 31, 75) rgb(183, 200, 219);
  overflow-x: hidden;
}

body {
  scroll-snap-type: y mandatory;
  margin: 0;
}

ul,
li {
  list-style-type: none;
}

p, a {
  letter-spacing: .03em;
}

p {
  font-family: Futura, Geneva, Tahoma, sans-serif;
  font-size: 1em;
  margin: 0;
  font-weight: normal;
  color: var(--main-color);
}

a {
  text-decoration: none;
}

button {
  font-family: Futura;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--main-color);
  font-family: bivoac-regular, Arial, Helvetica, sans-serif;
  text-align: center;
}

button {
  color: var(--main-color);
}

h1 {
  font-size: 6em;
  margin: 3% 0%;
}

h2 {
  font-size: 4em;
}

h3 {
  font-size: 2.5em;
}

h4 {
  font-size: 2em;
}

::placeholder {
  color: rgb(211, 211, 211);
}

#homepage {
  max-width: 90em;
  margin: auto;
}

.link-colorfade {
  color: var(--main-color);
  transition: color .5s;
  -webkit-transition: color .5s;
  -moz-transition: color .5s;
  -ms-transition: color .5s;
  -o-transition: color .5s;
}
.link-colorfade:hover {
  color: var(--main-accent-color);
} 

/*                        Animations         */
@keyframes fadeIn {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes modalBackgroundFadeIn {
  from {
    opacity: 0
  }

  to {
    opacity: .8
  }
}

@keyframes modalBackgroundFadeOut {
  from {
    opacity: .8
  }

  to {
    opacity: 0
  }
}