.reader-title-section {
    background-image: url('../../../Pics/Landscapes/mountainPool.png');
    height: auto;
}

#reader-title {
    margin-top: 0px;
    padding-top: 15%;
    color: #ccc;
}

#reader-mediums{
    margin-top: 0px;
}

.reader-info {
    height: auto;
    width: 100%;
}

.reader-infotext {
    padding: 2% 0% 8% 0%;
    width: 80%;
    color: rgb(55, 55, 55);
    margin: auto;
    text-align: center;
    font-size: 1.8em;
    color: white;
    text-shadow: .2em .2em 1em black;
}

.reader-bridgetext {
    font-size: 1em;
    padding: 2em;
    background: rgb(241,199,97);
    background: radial-gradient(farthest-side, rgba(241, 199, 97,.7) 0%, rgba(255,255,255,0) 99%, rgba(255,255,255,0) 100%);
    text-align: center;
}

#reader-titlecard {
    max-height: 2em;
    background-position-y: 35%;
}

@media only screen and (max-width: 760px) {
    .reader-bridgetext {
        font-size: .8em;
    }
}

@media only screen and (max-width: 760px) {
    #reader-titlecard {
        background-size: 150%;
    }
}

@media only screen and (max-width: 360px) {
    #reader-titlecard {
        height: 20vh;
        background-size: 180%;
    }
}

