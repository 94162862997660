.newscard-section {
  overflow: auto;
  overflow-y: hidden;
  height: auto;
}

.newscard {
  display: flex;
  height: 30em;
}

.newscards {
  margin: 0%;
  height: auto;
  overflow: auto;
}

.newscard-image-container {
  display: inline-block;
  background-position: 50% 50%;
  background-size: cover;
  padding: 0em;
  overflow: hidden;
  min-height: 25em;
}

.newscard-image {
  height: 100%;
  width: auto;
  position: relative;
}

.newscard-text,
.newscard-text-title {
  text-align: center;
  color: white;
}

.newscard-eyedrawing {
  height: 3em;
}

.newscard-text-container,
.newscard-image-container {
  width: 50%;
}

.newscard-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  scrollbar-width: none;
  min-height: 30em;
}

.newscard-text-title {
  padding-bottom: 0em;
  font-size: 2em;
  margin: 0em;
}

.newscard-text {
  width: 70%;
  font-size: 1em;
  margin: .3em auto;
}

#newscard-blurb1 {
  background-color: rgb(185, 158, 197);
}

#newscard-blurb2 {
  background-color: rgb(228, 186, 97);
}

#newscard-blurb3 {
  background-color: rgb(189, 188, 158);
}

.newscard-more-container {
  text-align: center;
}

.newscard-card {
  margin: 6% auto auto;
  padding: 2% 5%;
  width: 70%;
  height: auto;
  box-shadow: .1em .1em 1em rgba(17, 16, 16, 0.09), -.1em -.1em 1em rgba(16, 14, 14, 0.112);
  background-color: white;
}

#newscard-title {
  color: rgb(72, 31, 75);
  margin: 2% auto;
}

.newscard-infoflex {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
  padding-bottom: 5%;
}

.button1 {
  background-color: rgb(241, 199, 97);
  display: inline-block;
  width: auto;
  color: white;
  padding: .5em;
  font-size: 1.5em;
  position: relative;
  bottom: 5%;
  right: -95%;
  transition: transform 500ms, background-color 500ms, color 500ms;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transition: transform 500ms, background-color 500ms, color 500ms;
  -moz-transition: transform 500ms, background-color 500ms, color 500ms;
  -ms-transition: transform 500ms, background-color 500ms, color 500ms;
  -o-transition: transform 500ms, background-color 500ms, color 500ms;
}

.button1:hover {
  background-color: rgb(198, 171, 210);
  color: rgb(72, 31, 75);
}

.newscard-infoflex div {
  overflow-y: auto;
  width: 40%;
  margin: 2%;
}

@media only screen and (max-width: 1320px) {
  .newscard-text-container {
    font-size: .9em;
  }
  .newscard-text-title {
    padding: 1%;
  }
  .newscard-text {
    line-height: 1.2em;
    font-size: 1.1em;
  }
}

@media only screen and (max-width: 950px) {
  .newscard-text-container {
    font-size: .7em;
  }
}

@media only screen and (max-width: 750px) {
  .newscard {
    flex-direction: column;
    height: auto;
  }
  .newscard-text-container,
  .newscard-image-container {
    width: 100%;
  }
  .newscard-text,
  .newscard-text-title {
    padding: 1%;
  }
  .newscard-text-container {
    padding: 1em 0em;
  }
}