.biocard-title-container {
    width: 100%;
    text-align: center;
}

#biocard-title {
    color: rgb(163, 190, 221);
    margin-top: 5%;
    margin-bottom: 2%;
}

.biocard-flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 0%;
    margin:auto;
}

.biocard-flexbox {
    width: 19em;
    padding-bottom: 2em;
    cursor: pointer;
    margin: 2%;
    box-shadow: 0em 0em 0em rgb(241, 199, 97), 0em 0em 0em rgb(241, 199, 97);
    transition: box-shadow 1s ease-out;
    position: relative;
    -webkit-transition: box-shadow 1s ease-out;
    -moz-transition: box-shadow 1s ease-out;
    -ms-transition: box-shadow 1s ease-out;
    -o-transition: box-shadow 1s ease-out;
}

.biocard-flexbox:hover {
    box-shadow: 0em 0em 2em rgba(237, 199, 110, 0.782), 0em 0em 2em rgba(237, 199, 110, 0.782);
}

.biocard-flexbox:hover .biocard-button {
    background-color: var(--main-color);
}

.biocard-description-container {
    width: 100%;
    height: 50%;
}

.biocard-description {
    padding: 5%;
    overflow-y: scroll;
    scrollbar-width: none;
    height: 70%;
}

.biocard-image-container {
    position: sticky;
    width: 100%;
    height: 100%;
    height: auto;
    display: block;
    background-size: cover;
    height: 20em;
    margin: auto;
    overflow: hidden;
}

.biocard-name {
    color: white;
    text-shadow: 0em 0em 1em black;
    position: relative;
    font-size: 1.5em;
    z-index: 9;
    top: 80%;
    left: 10%;
}

.biocard-image {
    width: 100%;
    height: 100%;
    height: auto;
    display: block;
    background-size: cover;
}

.biocard-button {
    position: absolute;
    top: -30%;
    font-size: 1.2em;
    right: 50%;
    transform: translate(50%, 0);
    color: white;
    background-color: var(--secondary-orange);
    padding: .6em .9em;
    border-radius: 50%;
    border: .15em solid white;
    transition: background-color 500ms;
}

.biocard-info-container {
    padding: 0em .4em;
    position: sticky;
    height: 12em;
}

.biocard-infoName {
    font-size: 1.5em;
    margin-bottom: .3em;
}

.biocard-infoSign {
    display: block;
    text-align: center;
    font-size: 1em;
}

.biocard-modal-biocard {
    display: grid;
    grid-template-areas: "image info"
                        "image info"
                        "description description";
    grid-template-columns: 30% 70%;
    grid-template-rows: 50vh auto;
    text-align: center;
    width: 90%;
    padding: 5%;
}

#biocard-modal-container {
    height: auto;
}

@media only screen and (max-width: 880px) {
    .biocard-flexbox {
        width: 15em;
        max-width: 20em;
    }
}

@media only screen and (max-width: 521px) {
    .biocard-flexbox {
        width: 70%;
    }
}

@media only screen and (max-width: 521px) {
    .biocard-flexbox {
        width: 80%;
    }
}