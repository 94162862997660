.signup-stickymain {
    display: flex;
    flex-direction: column;
    height: 15em;
    background: white;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    animation-name: fadeIn;
    animation-duration: 1000ms;
    animation-fill-mode: forwards;
}

.signup-graphiccontainer {
    height: 3em;
}
#signup-eyegraphic {
    fill: var(--main-accent-color);
}

.signup-link {
    text-align: center;
    width: 100%;
}

#signup-signupbutton {
    display: inline-block;
}

#signup-closecontainer {
    height: 4em;
    position: absolute;
    top: 1em;
    right: .5em;
}
.signup-close {
    height: 100%;
}

#signup-textcontainer {
    text-align: center;
    font-size: 1.5em;
    padding: 1em;
}   

#signup-closeButton {
    height: 100%;
    padding: 0px;
    border: none;
    fill: var(--secondary-orange);
    background-color: white;
    transition: fill 500ms;
    -webkit-transition: fill 500ms;
    -moz-transition: fill 500ms;
    -ms-transition: fill 500ms;
    -o-transition: fill 500ms;
}
#signup-closeButton:hover {
    fill: var(--main-color);
}

@media only screen and (max-width: 600px) {
    .signup-stickymain {
        height: auto;
    }

    .signup-link, .signup-graphiccontainer {
        padding: 1em;
    }
}
