.item-container {
    width: 20em;
    height: 30em;
    margin: 0em 1em 2em 1em;
    box-shadow: 0em 0em 0em var(--secondary-orange), 0em 0em 0em var(--secondary-orange);
    transition: box-shadow 1s ease-out;
    -webkit-transition: box-shadow 1s ease-out;
    -moz-transition: box-shadow 1s ease-out;
    -ms-transition: box-shadow 1s ease-out;
    -o-transition: box-shadow 1s ease-out;
}

.item-container:hover {
    box-shadow: .5em .5em 2em var(--secondary-orange), -.5em -.5em 2em var(--secondary-orange);
}

.item {
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 20em;
    background-position: center;
    background-size: cover;
}

.item-header {
    padding: 1em;
}

.item-name {
    color: var(--main-color);
    font-size: 1em;
}

.item-booknow {
    position: absolute;
    bottom: -1.5em;
    right: 50%;
    transform: translate(50%, 0%);
}

.item-booknowbutton {
    color: white;
    padding: .65em 1em;
    border-radius: 50%;
    font-size: 1.2em;
    font-weight: bold;
    border: .15em solid white;
    background-color: var(--secondary-orange);
}