.divider {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.divider-text { 
    text-align: center;
    font-family: bivoac-regular;
    font-size: 3.1em;
    padding: .7em 0em;
    margin: .5em;
}

.divider-futura {
    font-family: Futura;
    font-size: 1.9em;
}

.divider-textglow {
    text-shadow: 0 0 30px var(--secondary-orange);
}

#divider-titleDivider {
    background: radial-gradient(farthest-side, rgba(241, 199, 97,.7) 0%, rgba(255,255,255,0) 99%, rgba(255,255,255,0) 100%);
    width: 90%;
    margin: auto;
}

.divider-sectionGraphic {
    width: 20%;
}

.divider-titleGraphic {
    width: 10%;
}

@media only screen and (max-width: 975px) {
    .divider-text {
        font-size: 2em;
    }

    .divider-futura {
        font-size: 1.3em;
    }

    .divider-titleGraphic {
        width: 7%;
    }
}

@media only screen and (max-width: 600px) {
    .divider-text {
        font-size: 1.5em;
    }
}

@media only screen and (max-width: 560px) {
    .divider-futura {
        font-size: 1em;
    }

    .divider-titleGraphic {
        width: 5%;
    }
}

