.video-flex {
    background-image: url('../../../Pics/SvgDrawings/video_background.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    display: block;
    margin: auto;
    padding-top: 8em;
    min-height: 530px;
    height: 20em;
    overflow-x: hidden;
    max-width: 90em;
}

.video-flexitem {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 85%;
}

.video-button-container {
    width: 100%;
    text-align: center;
    padding-top: 2em;
}

#video-morebutton {
    width: fit-content;
}

#video-morebutton:hover {
    color: white
}

.video-video {
    border: 0px;
    display: block;
    height: 100%;
    max-width: 65em;
    width: 80%;
    margin: auto
}

.video-heading, .video-text, .video-morebutton {
    margin: 1em 0em;
}

.video-text {
    font-size: 1.4em;
    padding: 1em 1em 1.5em 0em;
}

@media only screen and (max-width: 1050px) {
    .video-flex {
        height: 65vh;
    }
}

@media only screen and (max-width: 670px) {
    .video-flexitem {
        width: 100%;
    }
}
