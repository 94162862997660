.whyvibe {
    margin: auto;
    padding-bottom: 1em;
}

#whyvibe-titlecard {
    height: 50vh;
    background-size: cover;
}

.whyvibe-careers {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#whyvibe-footnote {
    padding: 2em;
    width: 80%;
    margin: auto;
}

/* --------------Value Card Section------------- */

.valuecards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
    margin: auto;
}

.valuecard {
    flex: 0 0 30%;
    min-width: 18em;
    border: .1em solid var(--secondary-orange);
    margin: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 5px 5px 10px var(--secondary-orange);
}

.valucard-titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7em;
}

.valuecard-title {
    font-size: 1.5em;
    margin: 0em .4em;
    width: 100%;
}

.valucard-text {
    height: 11em;
    text-align: center;
    font-size: 1em;
    padding: .7em;
}

@media only screen and (max-width: 1350px) {
    .valuecards {
        width: 90%;
    }
}

