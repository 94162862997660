/*
  main - violet                         rgb(72,31,75)
  main complement - light purple        rgb(198, 171, 210)
  accent 1 - grey                       rgb(202, 201, 171)
  accent 2 - light blue                 rgb(183, 200, 219)
  accent 3 - light pink                 rgb(253, 229, 183)
  accent 4 - light orange               rgb(241, 199, 97)
*/

.cardmodal {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
}

.cardmodal-container {  
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
    height: auto;
    width: 75vw;
    background-color: white;
    overflow-y: hidden;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.cardmodal-card {
    background-color: white;
}

.cardmodal-exit{
    top: 2em;
    right: 2em;
    position: absolute;
    z-index: 100;
    background:  rgb(241, 199, 97);
    padding: 1em;
    color: white;
    font-weight: bold;
    transform: scale(1);
    height: 2em;
    width: 2em;
    cursor: pointer;
    transition: background-color 500ms, color 500ms, transform 1s;
    -webkit-transition: background-color 500ms, color 500ms, transform 1s;
    -moz-transition: background-color 500ms, color 500ms, transform 1s;
    -ms-transition: background-color 500ms, color 500ms, transform 1s;
    -o-transition: background-color 500ms, color 500ms, transform 1s;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}

.cardmodal-exit:hover{
    background-color: rgb(198, 171, 210);
    color: rgb(72,31,75);
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.cardmodal-background {
    cursor: pointer;
    animation-name: modalBackgroundFadeIn;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    position: fixed;
    background-color: black;
    opacity: .8;
    top: 0%;
    right: 0%;
    width: 100%;
    height: 100%;
    z-index: 100;
}

@media only screen and (max-width: 1331px) {
    .cardmodal-container {
        width: 90vw;
    }
}
