.cat-section-heading {
    margin: 0%;
    width: 100%;
    padding-top: .5em;
}

#cat-section-titlecard {
    background-position-y: 60%;
    background-size: cover;
    height: 25vh;
}

@media only screen and (max-width: 600px) {
    #cat-section-titlecard {
        height: 20vh;
        min-height: 250px;
    }
}