
.dividergraphic-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.dividergraphic-svgContainer {
    display: inline-block;
    height: 1.2em;
}

#dividergraphic-lineContainer {
    width: 100%;
}

#dividergraphic-circleContainer {
    width: auto;
    height: 1.3em;
}

#dividergraphic-lineInbetween {
    width: 41%;
}

@media only screen and (max-width: 975px) {
    .divider-futura {
        font-size: 1.3em;
    }

    #dividergraphic-circleContainer {
        height: 1em;
    }
}


