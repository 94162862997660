.careersection {
    padding-bottom: 2em;
    background-color: rgb(185, 158, 197);
    text-align: center;
}

.career-title {
    padding: 1em 1em 0em 1em;
    margin: 0em;
}

.career-textcontainer {
    padding: 3em 2em;
}

.career-text {
    font-size: 1em;
    display: block;
    padding: .5em;
}

.career-eyeball {
    height: 6em;
    text-align: center;
    padding-bottom: 1em;
}