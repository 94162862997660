.actionbutton-container {
    display: inline-block;
    font-size: 1.5em;
    padding: .2em 2em;
    color: white;
    background-color: var(--secondary-orange);
    border: .1em solid var(--secondary-orange);
    border-color: var(--secondary-orange);
    transition: background-color 500ms, border-color 500ms;
    -webkit-transition: background-color 500ms, border-color 500ms;
    -moz-transition: background-color 500ms, border-color 500ms;
    -ms-transition: background-color 500ms, border-color 500ms;
    -o-transition: background-color 500ms, border-color 500ms;
}

.actionbutton-container:hover {
    background-color: rgb(241, 199, 97);
    background-color: var(--main-color);
    border: .1em solid var(--main-color);
    border-color: var(--main-color);
    cursor: pointer;
}

.actionbutton-text {
    padding: .5em;  
    color: rgb(241, 199, 97);
    transition: color 500ms;
    -webkit-transition: color 500ms;
    -moz-transition: color 500ms;
    -ms-transition: color 500ms;
    -o-transition: color 500ms;
}

@media only screen and (max-width: 600px) {
    .actionbutton-container {
        font-size: 1.2em;
    }
}